.Tilt {
  background: linear-gradient(89deg, #FF5EDF  0%, #04C8DE 100% );
  height: 100px;
  width: 100px;
}


.Tilt:hover {
  transform: scale(1.1);
}


.logo {
  display: flex;
  justify-content: flex-start;
}


@media only screen and (max-width:  600px) {
  .logo {
    justify-content: center;
  }
}

