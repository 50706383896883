body {
  margin: 0;
  padding: 0;
  font-family: "Courier New", Courier, monospace;
  background: linear-gradient(89deg, #FF5EDF  0%, #04C8DE 100% );
}

@font-face {
  font-family: 'SEGA LOGO FONT';
  font-style: normal;
  font-weight: normal;
  src: local('SEGA LOGO FONT'), url('./SEGA.woff') format('woff');
  }

.sega {
  font-family: "SEGA LOGO FONT";
  font-weight: 200;
  color: blue;
}

.title {
  font-weight: 0;
  font-size: 50px;
  color: blue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

.center {
  justify-content: center;
}


@media only screen and (max-width:  600px) {
  #root{
    margin: 10px;
  }
}


